var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('router-view',{attrs:{"name":"StudioNavBar"}}),_c('v-content',{class:{
      'content-bg': ![
        'SignIn',
        'SignUp',
        'Dashboard',
        'Video',
        'Detail'
      ].includes(this.$route.name)
        ? true
        : false
    }},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }